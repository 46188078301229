<!-- 双层仪表盘 -->
<template>
  <div class="charts-section" v-if="total">
    <div class="charts-title-mou">
      <h3 class="charts-title" v-if="showTitle">{{ tableTitle }}：{{ data }}</h3>
      <el-button plain icon="el-icon-download" @click="downloadImg" title="下载图片"></el-button>
    </div>
    <div class="charts-main-mou" :id="chartid"></div>
  </div>
</template>

<script>
import { Chart, registerShape } from "@antv/g2";
import { chartsFuns } from "./charts.js";

export default {
  data() {
    return {
      total: 1,
      loading: true,
      unit: "℃",
      chartType: "polar",
      cheight: 160
    };
  },
  props: [
    "data",
    "reverseColor",
    "tableTitle",
    "chartid",
    "credit15",
    "showTitle"
  ],
  watch:{
    data(){
      this.drawChart();
    }
  },
  mounted() {
      this.drawChart();
  }, 
  methods: {
    saveImg(){
      let title = this.tableTitle;
      chartsFuns.exportImgCallback(title, this.chartid).then(res=>{
        let data = {name:this.chartid,data:res}
        this.$store.commit('admin/setReportImg',data)
          // this.qualratingnm = res
      })
    },
    drawChart() {
      let that = this;
      let levelText = that.data,
        reverseColor = that.reverseColor,
        credit15 = that.credit15;
      var level,
        levelArr = [
          { level: 9, text: "高" },
          { level: 9, text: "大" },
          { level: 9, text: "成长速度快" },
          { level: 9, text: "好" },
          { level: 7, text: "成长速度较快" },
          { level: 7, text: "较高" },
          { level: 7, text: "较大" },
          { level: 7, text: "较好" },
          { level: 5, text: "中等" },
          { level: 5, text: "成长缓慢" },
          { level: 5, text: "一般" },
          { level: 5, text: "不明显" },
          { level: 3, text: "较低" },
          { level: 3, text: "较小" },
          { level: 3, text: "轻度衰退" },
          { level: 3, text: "较差" }, 
          { level: 3, text: "亏损" }, 
          { level: 1, text: "差" },
          { level: 1, text: "衰退" },
          { level: 1, text: "小" },
          { level: 1, text: "严重亏损" },
          { level: 1, text: "低" },
          { level: 0, text: "资不抵债" },
          { level: 0, text: "极低" }
        ];
      if (credit15) {
        levelArr = [];
        for (let y = 1; y <= 15; y++) {
          let obj = { level: 30 - (y * 2 - 1), text: "R" + y };
          levelArr.push(obj);
        }
      }
      if (!levelText) return;
      var c = levelArr.filter(function(item) {
        return item.text === levelText;
      });
      if (c.length) {
        level = c[0].level;
      } else {
        return "";
      }
      // color
      let color = ["#d60019", "#d65525", "#d69435", "#b0d63a", "#0fd731"];
      if (credit15) {
        color = [
          "#d60019",
          "#d62e16",
          "#d64323",
          "#d65525",
          "#d66128",
          "#d6712e",
          "#d69435",
          "#d6ac35",
          "#d6c837",
          "#cad63b",
          "#b0d63a",
          "#8ed63b",
          "#6cd63b",
          "#0fd731",
          "#0fd731"
        ];
      }
      if (reverseColor) {
        color.reverse();
      }

      let data = [{ value: level }];
      // 自定义Shape 部分
      registerShape("point", "pointer", {
        draw(cfg, container) {
          const group = container.addGroup({});
          // 获取极坐标系下画布中心点
          const center = this.parsePoint({ x: 0, y: 0 });

          // 绘制指针
          let cx = center.x,
            cy = center.y;
          group.addShape("polygon", {
            attrs: {
              points: [
                [cfg.x - (cx - cfg.x) / 6, cfg.y - (cy - cfg.y) / 6],
                [cx + (cy - cfg.y) / 12, cy - (cx - cfg.x) / 12],
                [cx + (cx - cfg.x) / 6, cy + (cy - cfg.y) / 6],
                [cx - (cy - cfg.y) / 12, cy + (cx - cfg.x) / 12]
              ],
              stroke: cfg.color,
              lineWidth: 1,
              fill: cfg.color
            }
          });
          return group;
        }
      });
      const chart = new Chart({
        container: that.chartid,
        autoFit: true,
        height: that.cheight,
        padding: [0, 0, 30, 0]
      });
      chart.data(data);
      chart.animate(false);
      const view1 = chart.createView();
      view1.coordinate("polar", {
        startAngle: -Math.PI,
        endAngle: "0",
        radius: 0.6
      });
      view1.scale("value", {
        min: 0,
        max: color.length * 2,
        tickInterval: 2
      });

      view1.axis("1", false);
      view1.axis("value", {
        line: 1,
        label: false,
        tickLine: {
          length: -24
        },
        grid: null
      });
      view1.legend(false);
      view1.tooltip(false);
      view1
        .point()
        .position("value*1")
        .shape("pointer")
        .color("value", val => {
          let n = Math.floor(val / 2);
          return color[n];
        });

      const view2 = chart.createView();
      view2.coordinate("polar", {
        startAngle: -Math.PI,
        endAngle: "0",
        radius: 0.75
      });
      view2.scale("value", {
        min: 0,
        max: color.length * 2,
        tickInterval: 2
      });
      view2.axis("1", false);
      /**/
      view2.axis("value", {
        line: 1,
        label: false,
        tickLine: {
          length: -24
        },
        grid: null
      });
      view2.legend(false);
      view2.tooltip(false);
      view2
        .point()
        .position("value*2")
        .shape("pointer")
        .color("value", val => {
          let n = Math.floor(val / 2);
          return color[n];
        });
      draw(data);

      function draw(data) {
        // const val = data[0].value;
        view1.annotation().clear(true);
        view2.annotation().clear(true);
        // 绘制仪表盘背景
        for (let k = 0; k < color.length; k++) {
          let ks = k * 2,
            ke = (k + 1) * 2;
          view1.annotation().arc({
            top: false,
            start: [ks, 1],
            end: [ke, 1],
            style: {
              stroke: color[k],
              lineWidth: 5,
              lineDash: null
            }
          });
          view2.annotation().arc({
            top: false,
            start: [ks, 1],
            end: [ke, 1],
            style: {
              stroke: color[k],
              lineWidth: 15,
              lineDash: null
            }
          });
        }

        // 绘制指标数字
        view1.annotation().text({
          position: ["50%", "65%"],
          content: levelText,
          style: {
            fontSize: 16,
            fill: "#545454",
            textAlign: "center"
          }
        });
        // view1.annotation().text({
        //   position: ["50%", "90%"],
        //   content: `${data[0].value} %`,
        //   style: {
        //     fontSize: 36,
        //     fill: "#545454",
        //     textAlign: "center"
        //   },
        //   offsetY: 15
        // });
        view1.changeData(data);
        view2.changeData(data);
      }
      setTimeout(() => {
        this.saveImg()
      }, 300);
    },
    downloadImg() {
      let title = this.tableTitle;
      chartsFuns.exportImg(title, this.chartid);
    },
    detail() {}
  }
};
</script>
<style scoped>
.charts-title-mou {
  border-bottom: 1px solid #ebeef5;
  background: rgba(153, 153, 255, 0.05);
}
</style>